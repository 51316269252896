<template>
  <address-field
      @save="selectAddress"
      v-model="address"
      label="Your address"
      :error-messages="errorMessages"
  >

    <template v-slot:activator="{ on }">
      <btn v-on="on" color="primary">Calculate delivery fee</btn>
    </template>
  </address-field>
</template>

<script>
import { distanceDiff, setBasketAddress} from "@/graphql";
import AddressField from '@/components/AddressField'

export default {
  props: ['store', 'product', 'basket'],
  components: { AddressField},
  data: () => ({
    mode: 'search',
    dialog: false,
    valid: null,
    search: null,
    address: null,
    loading: false,
    error: false,
    errorMessage: null,
    rules: {
      required: v => !!v || 'This field is required',
      minThree: v => !!v && v.length > 3 || 'Please enter at least 3 characters'
    },
    addressChoices: []
  }),
  apollo: {
    distanceDiff: {
      query: distanceDiff,
      variables() {
        if (this.address && this.address.value) {
          const [latitude, longitude] = this.address.value
          return {latitude, longitude}
        }
      },
      skip() {
        return !this.address
      }
    }
  },
  computed: {
    errorMessages() {
      let errors = []
      if (this.distanceDiff && this.distanceDiff > this.store.maxDeliveryRadius) {
        errors.push('Unable to deliver your order to this address - too far from store')
      }
      if (this.required && !this.address) {
        errors.push('You must provide an address for delivery orders')
      }
      return errors
    }
  },
  methods: {
    resetSearch() {
      this.$refs.form.reset()
      this.mode = 'search'
      this.search = null
    },
    selectAddress() {
      this.loading = true
      const {text: address, value: [latitude, longitude]} = this.address
      this.$apollo.mutate({
        mutation: setBasketAddress,
        variables: {
          latitude, longitude, address,
          basket: this.basket.identifier,
        }
      }).then((result) => {
        if (result) {
          this.loading = false
          this.dialog = false
          this.$emit('change')
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message
        this.loading = false
      })
    }
  }
}
</script>