<template>
    <div v-if="$apollo.loading"></div>
    <div v-else-if="basket">
        <div class="px-lg-16 secondary">
            <v-breadcrumbs :items="breadcrumbs" :dark="getDarkMode('secondary')" class="secondary">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
            </v-breadcrumbs>
        </div>
        <v-container class="px-md-16">
            <h1>Basket</h1>
            <v-alert type="warning" outlined class="mt-3" v-if="basket && basket.diffTimeRanges">
                Items in this order contain different collection/return times. Please make sure this is really want you want to do!
            </v-alert>
            <v-simple-table class="mt-10" v-show="$vuetify.breakpoint.mdAndUp">
                <template>
                    <thead>
                    <tr>
                        <th class="text-left title">Product</th>
                        <th class="text-left title">Collection and return time</th>
                        <th class="text-left title">Number of items</th>
                        <th class="text-left title"><span v-if="hidePrices">Duration</span><span v-else>Rates</span></th>
                        <th class="text-left title" v-if="!hidePrices">Subtotal</th>
                        <th class="text-left title">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in basket.lineItems" :key="item.name">
                        <td>
                            <div class="py-10">
                                <div>{{ item.assets[0].product.name }}</div>
                                <div class="grey--text">
                                    <v-icon small>mdi-tag</v-icon> {{item.assets[0].product.category.name }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="py-10">
                                <div><v-icon small>mdi-calendar-clock</v-icon> {{ item.startTime | niceTime }}</div>
                                <div><v-icon small>mdi-backup-restore</v-icon> {{ item.endTime | niceTime }}</div>
                            </div>
                        <td>{{ item.assets.length }}</td>
                        <td v-if="hidePrices">{{ item.quantity }} {{item.plan.period.toLowerCase()}}{{ item.quantity * item.plan.interval > 1 ? 's' : '' }}</td>
                        <td v-else>{{ item.assets.length * item.plan.interval }} x {{ item.quantity }} {{item.plan.period.toLowerCase()}}{{ item.quantity * item.plan.interval > 1 ? 's' : '' }} @ {{ store.currency.symbol }} {{ item.plan.price }} per {{ item.plan.period.toLowerCase()}}</td>
                        <td v-if="!hidePrices">{{ store.currency.symbol }} {{ item.subtotal }}</td>
                        <td>
                            <delete-line-item :item-id="item.id" @delete="$apollo.queries.basket.refetch()"></delete-line-item>
                        </td>
                    </tr>
                    <tr v-if="basket.deliveryFee">
                        <td colspan="3"></td>
                        <td>DELIVERY FEE</td>
                        <td>{{ store.currency.symbol }} {{ basket.deliveryFee }}</td>
                        <td></td>
                    </tr>

                    <tr v-if="!hidePrices">
                        <td colspan="3"></td>
                        <td>GRAND TOTAL:</td>
                        <td>{{ store.currency.symbol }} {{ grandTotal }}</td>
                        <td></td>
                    </tr>
                        
                    <tr v-if="store.billingPercentage < 100 && !hidePrices">
                        <td colspan="3"></td>
                        <td>DUE NOW: ({{store.billingPercentage}}%)</td>
                        <td>{{ store.currency.symbol }} {{grandTotal * (store.billingPercentage / 100)}} </td>
                        <td></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
            <v-list subheader v-if="basket.lineItems && basket.lineItems.length > 0" v-show="$vuetify.breakpoint.smAndDown">
              <v-subheader>Start/end times</v-subheader>
              <v-list-item >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon small>mdi-calendar-clock</v-icon> {{ basket.lineItems[0].startTime | niceTime }}
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-icon small>mdi-backup-restore</v-icon> {{ basket.lineItems[0].endTime | niceTime }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-subheader>Items</v-subheader>
              <template v-for="(item, i) in basket.lineItems">
                  <v-list-item :key="item.id">
                    <v-list-item-content>
                    <v-list-item-title>
                      {{ item.assets[0].product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.assets.length * item.plan.interval }} x {{ item.quantity }} {{item.plan.period.toLowerCase()}}{{ item.quantity * item.plan.interval > 1 ? 's' : '' }} @ {{ store.currency.symbol }} {{ item.plan.price }} per {{ item.plan.period.toLowerCase()}} = {{ store.currency.symbol }} {{ item.subtotal }}
                    </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <delete-line-item :item-id="item.id" @delete="$apollo.queries.basket.refetch()"></delete-line-item>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="i"></v-divider>
              </template>


              <v-list-item v-if="basket.deliveryFee">
                <v-list-item-content>
                  <v-list-item-title>{{ store.currency.symbol }} {{ basket.deliveryFee }}</v-list-item-title>
                  <v-list-item-subtitle>Delivery fee</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                <v-list-item-title>
                  {{ store.currency.symbol }} {{ grandTotal }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Grand total
                </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card flat>
            <v-card-actions>
                <v-spacer/>
                <add-delivery-address v-if="basket.deliveryAddressRequired" :basket="basket" :store="store" @change="$apollo.queries.basket.refetch()"/>
                <place-order v-else :basket="basket" :disabled="placeOrderDisabled"></place-order>
                <v-spacer/>
            </v-card-actions>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    import moment from 'moment'
    import {basket} from '@/graphql'
    import { mapState, mapGetters } from 'vuex'
    import Cookie from 'js-cookie'
    import AddDeliveryAddress from "@/components/modals/AddDeliveryAddress";

    export default {
        components: { AddDeliveryAddress },
        apollo: {
            basket: {
                query: basket,
                variables: {
                    pk: Cookie.get('basket')
                }
            },
        },
        filters: {
            niceTime (val) {
                return moment.parseZone(val).format('LLL')
            }
        },
        methods: {
            updateBasket () {
                this.$emit('updateBasket');
            },
            openTerms () {
              this.$router.push('/legal')
            }
        },
        data: () => ({
            acceptTerms: false,
        }),
        computed: {
            ...mapState(['store']),
            ...mapGetters(['getDarkMode']),
            hidePrices () {
              return !this.placeOrderDisabled && this.grandTotal === 0;
            },
            placeOrderDisabled () {
                if (this.basket && this.basket.lineItems.length === 0) {
                    return true
                }
                return false
            },
            grandTotal () {
                if (this.basket && this.basket.lineItems.length > 0) {
                    const subtotal = this.basket.lineItems.map(li => li.subtotal).reduce((a, b) => a+b)
                    return subtotal + this.basket.deliveryFee
                }
                return 0
            },
            breadcrumbs () {
                return [
                        {
                            text: this.store.name,
                            href: `/`
                        },
                        {
                            text: 'Basket',
                            disabled: true
                        }
                    ]
            }
        }
    }

</script>
